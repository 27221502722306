<template>
    <v-dialog v-model="aberto" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="900" persistent
              @keydown.esc="fechar">
        <v-card class="card-detalhes">
            <v-card-title class="titulo-card cor-principal">
                <div class="d-flex justify-space-between w-100">
                    <div>Detalhes do Cliente</div>
                    <v-btn color="var(--cor-primaria-100)" dark icon @click="fechar()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-card-title>

            <v-card-text v-if="!!dados" class="mt-5">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                                :value="dados.nome"
                                dense
                                hide-details
                                label="Nome do Cliente"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.email"
                                dense
                                hide-details
                                label="Email do Cliente"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.cpf"
                                dense
                                hide-details
                                label="CPF do Cliente"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.rg"
                                dense
                                hide-details
                                label="RG do Cliente"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dataNascimento"
                                dense
                                hide-details
                                label="Data de Nascimento"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.ddd"
                                dense
                                hide-details
                                label="DDD"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.telefone"
                                dense
                                hide-details
                                label="Telefone"
                                outlined
                                readonly
                        />
                    </v-col>
                </v-row>

                <v-divider class="mt-6"></v-divider>

                <div class="mt-4">
                    <span class="font-weight-bold font-size-16">Endereço do Locatário</span>
                </div>

                <v-row class="mt-2">
                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.cep"
                                dense
                                hide-details
                                label="CEP do Locatário"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.numeroResidencia"
                                dense
                                hide-details
                                label="Número do Locatário"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.complemento"
                                dense
                                hide-details
                                label="Complemento do Locatário"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.logradouro"
                                dense
                                hide-details
                                label="Logradouro do Locatário"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.bairro"
                                dense
                                hide-details
                                label="Bairro do Locatário"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.cidade"
                                dense
                                hide-details
                                label="Cidade do Locatário"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="ufLocatario"
                                dense
                                hide-details
                                label="UF do Locatário"
                                outlined
                                readonly
                        />
                    </v-col>
                </v-row>

                <v-divider class="mt-6"/>

                <div class="mt-4">
                    <span class="font-weight-bold font-size-16">Documentos da locação</span>
                </div>

                <v-row class="mb-4 mt-6">
                    <v-col cols="12" md="6">
                        <file-uploader
                                :campos="[{
                  name: 'tipo',
                  value: 'contrato'
                }]"
                                :url="urlUpload"
                                :url-visualizar="dados.contrato_imobiliaria"
                                :visualizar="!!dados.contrato_imobiliaria"
                                nome="contrato_imobiliaria"
                                placeholder="Selecione o arquivo do contrato"
                                titulo="contrato"
                                @upload-finalizado="atualizarDados"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <file-uploader
                                :campos="[{
                  name: 'tipo',
                  value: 'vistoria'
                }]"
                                :url="urlUpload"
                                :url-visualizar="dados.vistoria_imobiliaria"
                                :visualizar="!!dados.vistoria_imobiliaria"
                                nome="vistoria_imobiliaria"
                                placeholder="Selecione o arquivo da vistoria"
                                titulo="vistoria"
                                @upload-finalizado="atualizarDados($event, true)"
                        />
                    </v-col>
                </v-row>

                <v-divider class="mt-6"></v-divider>

                <div class="mt-4">
                    <span class="font-weight-bold font-size-16">Dados da Locação</span>
                </div>

                <v-row class="mt-2">
                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="tipoImovel"
                                dense
                                hide-details
                                label="Tipo de Imóvel"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.cepLocacao"
                                dense
                                hide-details
                                label="CEP do Imóvel"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.numeroResidenciaLocacao"
                                dense
                                hide-details
                                label="Número do Imóvel"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.complementoLocacao"
                                dense
                                hide-details
                                label="Complemento do Imóvel"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.logradouroLocacao"
                                dense
                                hide-details
                                label="Logradouro do Imóvel"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.bairroLocacao"
                                dense
                                hide-details
                                label="Bairro do Imóvel"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="dados.cidadeLocacao"
                                dense
                                hide-details
                                label="Cidade do Imóvel"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="ufImovel"
                                dense
                                hide-details
                                label="UF do Imóvel"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                v-money="configMonetario"
                                :value="valorAluguel"
                                dense
                                hide-details
                                label="Valor do Aluguel"
                                outlined
                                prefix="R$"
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                v-money="configMonetario"
                                :value="valorCondominio"
                                dense
                                hide-details
                                label="Valor do Condomínio"
                                outlined
                                prefix="R$"
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                v-money="configMonetario"
                                :value="valorAgua"
                                dense
                                hide-details
                                label="Valor do Conta de Água"
                                outlined
                                prefix="R$"
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                v-money="configMonetario"
                                :value="valorLuz"
                                dense
                                hide-details
                                label="Valor do Conta de Luz"
                                outlined
                                prefix="R$"
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                v-money="configMonetario"
                                :value="valorIptu"
                                dense
                                hide-details
                                label="Valor do IPTU (mensal)"
                                outlined
                                prefix="R$"
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                v-money="configMonetario"
                                :value="taxaSetup"
                                dense
                                hide-details
                                label="Taxa Setup"
                                outlined
                                prefix="R$"
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                :value="pacoteAtual.nome"
                                dense
                                hide-details
                                label="Pacote"
                                outlined
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                v-money="configMonetario"
                                :value="pacoteAtual.porcentagem"
                                dense
                                hide-details
                                label="Porcentagem do Pacote"
                                outlined
                                prefix="%"
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                v-money="configMonetario"
                                :value="valorTotal"
                                dense
                                hide-details
                                label="Total Anual das Despesas"
                                outlined
                                prefix="R$"
                                readonly
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                v-money="configMonetario"
                                :value="valorTotalComPacote"
                                dense
                                hide-details
                                label="Total a Pagar"
                                outlined
                                prefix="R$"
                                readonly
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="orange"
                        text
                        @click="fechar">
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment";
import mixinGeral from "../../mixin/geral";
import helpers from "@/assets/js/helpers";
import FileUploader from "@/components/FileUploader.vue";
import {mapActions} from "vuex";

export default {
    name: "DialogDetalhes",
    mixins: [mixinGeral],
    components: {FileUploader},
    data() {
        return {
            aberto: false,
            dados: null,
        };
    },
    computed: {
        tipoImovel() {
            if (!this.dados) return "";
            return this.dados.tipoContrato === "residencial" ? "Residencial" : "Comercial";
        },
        urlUpload() {
            return `${process.env.VUE_APP_URLBASE}/api/clientes/${this.dados.id}/upload-documento`;
        },
        backgroundInput() {
            return "grey lighten-3";
        },
        dataNascimento() {
            if (!this.dados) return "";
            const data = this.dados.dataNascimento;
            return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
        },
        pacoteAtual() {
            const idAtual = this.dados.venda_atual.pacote_id;

            return this.pacotes.find((pacote) => {
                return pacote.id === idAtual;
            });
        },
        valorTotal() {
            const aluguel = this.dados.venda_atual.valor_aluguel;
            const condominio = this.dados.venda_atual.valor_condominio;
            const agua = this.dados.venda_atual.valor_agua;
            const luz = this.dados.venda_atual.valor_luz;
            const iptu = this.dados.venda_atual.valor_iptu;
            const setup = this.dados.venda_atual.taxa_setup;

            const totalAluguel = aluguel * 12;
            const totalCondominio = condominio * 12;
            const totalAgua = agua * 12;
            const totalLuz = luz * 12;
            const totalIptu = iptu * 12;

            const resultado = totalAluguel + totalCondominio + totalAgua + totalLuz + totalIptu + setup;

            return helpers.numericoParaMonetarioBrasil(resultado.toFixed(2));
        },
        valorTotalComPacote() {
            const valorTotal = helpers.monetarioBrasilParaNumerico(this.valorTotal);

            if (!this.pacoteAtual || valorTotal === 0) return 0;

            const total = helpers.monetarioBrasilParaNumerico(this.valorTotal);
            const porcentagem = Number(this.pacoteAtual.porcentagem);
            const taxaSetup = helpers.monetarioBrasilParaNumerico(this.taxaSetup);
            const totalSemTaxa = total - taxaSetup;
            const resultadoSemTaxa = (totalSemTaxa * porcentagem) / 100;
            const final = resultadoSemTaxa + taxaSetup;

            return helpers.numericoParaMonetarioBrasil(final.toFixed(2));
        },
        configMonetario() {
            return {
                decimal: ",",
                thousands: ".",
                precision: 2,
                masked: false,
            };
        },
        ufLocatario() {
            const ufs = helpers.obterUfs();
            return ufs.find((uf) => uf.id === this.dados.uf).label;
        },
        ufImovel() {
            const ufs = helpers.obterUfs();
            const uf = ufs.find((uf) => uf.id === this.dados.ufLocacao);
            return !!uf ? uf.label : "";
        },
        valorAluguel() {
            if (!this.dados || !this.dados.venda_atual.valor_aluguel) return "0,00";
            return helpers.numericoParaMonetarioBrasil(this.dados.venda_atual.valor_aluguel);
        },
        valorCondominio() {
            if (!this.dados || !this.dados.venda_atual.valor_condominio) return "0,00";
            return helpers.numericoParaMonetarioBrasil(this.dados.venda_atual.valor_condominio);
        },
        valorAgua() {
            if (!this.dados || !this.dados.venda_atual.valor_agua) return "0,00";
            return helpers.numericoParaMonetarioBrasil(this.dados.venda_atual.valor_agua);
        },
        valorLuz() {
            if (!this.dados || !this.dados.venda_atual.valor_luz) return "0,00";
            return helpers.numericoParaMonetarioBrasil(this.dados.venda_atual.valor_luz);
        },
        valorIptu() {
            if (!this.dados || !this.dados.venda_atual.valor_iptu) return "0,00";
            return helpers.numericoParaMonetarioBrasil(this.dados.venda_atual.valor_iptu);
        },
        taxaSetup() {
            if (!this.dados || !this.dados.venda_atual.taxa_setup) return "0,00";
            return helpers.numericoParaMonetarioBrasil(this.dados.venda_atual.taxa_setup);
        },
    },
    methods: {
        ...mapActions({obterInformacoes: "actionObterInformacoes"}),
        emitirAlteracao() {
            this.$emit("atualizar-dados");
        },
        atualizarDados(url, vistoria = false) {
            this.obterInformacoes();
            const dadosImob = JSON.parse(JSON.stringify(this.dados));
            vistoria ? dadosImob.vistoria_imobiliaria = url : dadosImob.contrato_imobiliaria = url;
            this.dados = dadosImob;
            this.emitirAlteracao();
        },
        exibir() {
            this.aberto = true;
        },
        fechar() {
            this.aberto = false;
            this.dados = null;
        },
    },
    created() {
    },
};
</script>

<style scoped>

.card-detalhes {
    border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
    font-size: 20px;
}

.cor-principal {
    color: var(--cor-primaria-100) !important;
}
</style>
