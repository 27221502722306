import {backend} from "./backend";
import helpers from "../assets/js/helpers";
import store from "@/store";

const renovarContrato = async (cliente, formulario) => {
    let uri = `api/clientes/${cliente}/renovar-contrato`;
    let response = await backend.post(uri, formulario, helpers.montarHeaderToken());
    return response;
};
const cadastrar = async (formulario) => {
    let uri = "api/clientes";
    let dados = traduzirFormularioCliente(formulario);
    let response = await backend.post(uri, dados, helpers.montarHeaderToken());
    return response;
};
const editar = async (id, formulario) => {
    let uri = `api/clientes/${id}`;
    let dados = traduzirFormularioCliente(formulario);
    let response = await backend.put(uri, dados, helpers.montarHeaderToken());
    return response;
};
const buscar = async (id) => {
    store.commit('SET_LOADING', true)
    let uri = `api/clientes/${id}`;
    let response = await backend.get(uri, helpers.montarHeaderToken());
    store.commit('SET_LOADING', false)
    return response;
};


const listar = async (id) => {
    store.commit('SET_LOADING', true)
    let uri = `api/clientes`;
    let response = await backend.get(uri, helpers.montarHeaderToken());
    store.commit('SET_LOADING', false)
    return response;
};

const excluir = async (id) => {
    let uri = `api/clientes/${id}`;
    let response = await backend.delete(uri, helpers.montarHeaderToken());
    return response;
};

const reconsultarSerasa = async (id) => {
    let uri = `api/clientes/${id}/reconsultar`;
    let response = await backend.get(uri, helpers.montarHeaderToken());
    return response;
};

const enviarContrato = async (id, qtdDias) => {
    let uri = `api/clientes/${id}/criar-contrato`;
    let objDados = {
        "qtd_dias_validade": qtdDias
    };
    let response = await backend.post(uri, objDados, helpers.montarHeaderToken());
    return response;
};

const reenviarContrato = async (id) => {
    let uri = `api/clientes/${id}/reenviar-contrato`;
    let response = await backend.get(uri, helpers.montarHeaderToken());
    return response;
};

const cancelarContrato = async (id) => {
    let uri = `api/clientes/${id}/cancelar-contrato`;
    let response = await backend.get(uri, helpers.montarHeaderToken());
    return response;
};

const obterUrlContrato = async (id) => {
    let uri = `api/clientes/${id}/url-contrato`;
    let response = await backend.get(uri, helpers.montarHeaderToken());
    return response;
};

const inativarCliente = async (id) => {
    let uri = `api/clientes/${id}/atualizar-status/2`;
    let response = await backend.put(uri, [], helpers.montarHeaderToken());
    return response;
};
const atualizarSignatario = async (id) => {
    let uri = `api/clientes/${id}/editar-email-signatario`;
    return await backend.put(uri, [], helpers.montarHeaderToken());
};

export default {
    listar,
    buscar,
    cadastrar,
    editar,
    excluir,
    reconsultarSerasa,
    enviarContrato,
    cancelarContrato,
    reenviarContrato,
    obterUrlContrato,
    renovarContrato,
    inativarCliente,
    atualizarSignatario,
};

const traduzirFormularioCliente = (formulario) => {
    return {
        nome: formulario.nome,
        email: formulario.email,
        cpf: formulario.cpf,
        rg: formulario.rg,
        dt_nascimento: formulario.dataNascimento,
        ddd: formulario.ddd,
        telefone: formulario.telefone,
        cep: formulario.cep,
        numero_residencia: formulario.numeroResidencia,
        logradouro: formulario.logradouro,
        bairro: formulario.bairro,
        cidade: formulario.cidade,
        uf: formulario.uf,
        valor_aluguel: helpers.monetarioBrasilParaNumerico(formulario.valorAluguel),
        valor_condominio: helpers.monetarioBrasilParaNumerico(formulario.valorCondominio),
        valor_agua: helpers.monetarioBrasilParaNumerico(formulario.valorAgua),
        valor_luz: helpers.monetarioBrasilParaNumerico(formulario.valorLuz),
        valor_iptu: helpers.monetarioBrasilParaNumerico(formulario.valorIptu),
        taxa_setup: helpers.monetarioBrasilParaNumerico(formulario.taxaSetup),
        pacote_id: formulario.pacoteId,
        cep_locacao: formulario.cepLocacao,
        numero_residencia_locacao: formulario.numeroResidenciaLocacao,
        logradouro_locacao: formulario.logradouroLocacao,
        bairro_locacao: formulario.bairroLocacao,
        cidade_locacao: formulario.cidadeLocacao,
        uf_locacao: formulario.ufLocacao,

        complemento: formulario.complemento,
        complemento_locacao: formulario.complementoLocacao,
        tipo_contrato: formulario.tipoContrato,
    }
};
