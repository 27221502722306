<template>
  <div :class="[nome, 'file-uploader', visualizar ? 'visualizar' : '']">
    <label :for="visualizar ? '' : nome"
           @click="abrirUrl"
           class="font-size-16 cursor-pointer text-center py-3 rounded d-block">
      <v-icon v-if="!carregando" :color="cor">
        {{ icone }}
      </v-icon>
      <v-progress-circular v-if="carregando" size="24" indeterminate class="mr-2" color="orange darken-2"/>
      {{ texto }}
      <div v-if="!visualizar" class="d-none">
        <input @change="fazerUpload" ref="upload" :id="nome" type="file" style="display: none;">
      </div>
    </label>
  </div>
</template>

<script lang="ts">
import mixinGeral from "../mixin/geral";
import helpers from '../assets/js/helpers.js';
import axios from "axios";

export default {
  name: 'FileUploader',
  mixins: [mixinGeral],
  props: {
    titulo: {
      type: String,
      default: 'o arquivo',
    },
    placeholder: {
      type: String,
      default: 'Selecione um arquivo',
    },
    nome: {
      type: String,
      default: 'arquivo',
    },
    url: {
      type: String,
      default: '',
    },
    urlVisualizar: {
      type: String,
      default: '',
    },
    campos: {
      type: Array,
      default: () => ([]),
    },
    visualizar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    carregando: false,
  }),
  computed: {
    cor() {
      return this.visualizar && this.urlVisualizar ? 'orange darken-1' : 'grey darken-1';
    },
    icone() {
      return this.visualizar ? 'mdi-eye' : 'mdi-cloud-upload';
    },
    texto() {
      if (this.carregando) return 'Fazendo upload...';
      if (this.visualizar && this.urlVisualizar) return 'Visualizar ' + this.titulo;
      if (this.visualizar && !this.urlVisualizar) return 'Nenhum arquivo de ' + this.titulo + ' selecionado';
      return this.placeholder ? this.placeholder : 'Selecione um arquivo';
    }
  },
  methods: {
    abrirUrl() {
      if (this.urlVisualizar && this.visualizar)
        window.open(this.urlVisualizar, '_blank');
    },
    fazerUpload(evt) {
      const {headers} = helpers.montarHeaderToken();
      headers['Content-Type'] = 'multipart/form-data';
      const formData = new FormData();
      this.campos.forEach(campo => formData.append(campo.name, campo.value));
      formData.append(this.nome, evt.target.files[0]);
      this.carregando = true;
      axios.post(
          this.url,
          formData,
          {
            headers
          }
      ).then((resp) => {
        evt.target.value = null;
        this.carregando = false;
        this.notificacaoSucesso('Arquivo enviado com sucesso!');
        this.$emit('upload-finalizado', resp.data);
      }).catch((error) => {
        evt.target.value = null;
        this.carregando = false;
        this.interpretarErro(error);
      });
    },
  },
};


</script>

<style scoped lang="scss">
.file-uploader {
  label {
    font-size: 12px;
    border: 1px dashed #AAAAAA;
  }

  &.visualizar {
    label {
      border-color: var(--cor-primaria-100);
    }
  }
}
</style>
