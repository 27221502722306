<template>
  <v-dialog
      v-model="aberto"
      width="800px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      @keydown.esc="fechar">
    <v-card class="card-detalhes d-flex justify-space-between flex-column">
      <v-card-title class="titulo-card cor-principal" v-if="!editando">
        <div class="d-flex justify-space-between w-100">
          <div>
            Nova Inadimplência
            <span v-if="dados.cliente_id">
              para: {{ dados.cliente }}
            </span>
          </div>
          <v-btn icon dark @click="fechar" color="orange">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-title class="titulo-card cor-principal" v-else>
        <div class="d-flex justify-space-between w-100">
          <div>
            Editando Inadimplencia Nº {{ form.inadimplencia_id }}
          </div>
          <v-btn icon dark @click="fechar" color="orange">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5">
        <v-row v-if="dados.cliente_id == null && !editando">
          <v-col cols="12">
            <AutocompleteClientes
                :id="dados.cliente_id"
                v-on:clienteSelecionado="clienteRetornado"
            />
          </v-col>
        </v-row>

        <v-card-text class="text-center"
                     v-else-if="!clienteAtual.vistoria_imobiliaria || !clienteAtual.contrato_imobiliaria && !editando">
          Os arquivos de contrato e vistoria não foram adicionados ao cadastro do cliente.
          <br>
          Faça o envio dos arquivos antes de cadastrar uma inadimplência.
          <br>
          <v-btn color="orange darken-2 mt-5" dark @click="abrirDetalhesClientes">
            Visualizar dados do cliente
          </v-btn>
        </v-card-text>

        <div v-else>
          <v-row>
            <v-col cols="12">
              <v-card class="card-simple elevation-0 py-3 mb-2">
                <v-row justify="center">
                  <v-col md="4" align="center">
                    <h4>Valor Total de Garantia:</h4>
                    <span>{{ dados.garantia | dinheiro }}</span>
                  </v-col>
                  <v-col md="4" align="center">
                    <h4>Saldo Utilizado:</h4>
                    <span>{{ dados.total_garantia_utilizado | dinheiro }}</span>
                  </v-col>
                  <v-col md="4" align="center">
                    <h4>Saldo Disponivel:</h4>
                    <span>{{ dados.garantia - dados.total_garantia_utilizado | dinheiro }}</span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mb-2 card-simple elevation-0 pb-4">
                <span class="ml-3 font-size-13">Iten(s) referente: </span>
                <v-row class="pl-10">
                  <v-col cols="6" md="4" v-for="(item, key) in checkbox_itens" :key="key" class="mt-n4">
                    <v-checkbox
                        :label="key | formataTitulo"
                        :value="key"
                        v-model="form.itens"
                        multiple
                        hide-details
                    >
                    </v-checkbox>
                    <!--<span class="ml-8">{{ dados[key] | dinheiro }}</span>-->
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  label="Valor com base nos itens Escolhidos*"
                  v-model="form.valor"
                  outlined
                  dense
                  hide-details
                  prefix="R$"
                  type="number"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-file-input
                  accept="image/*,.pdf"
                  label="Anexar Fatura para Pagamento"
                  counter
                  outlined
                  dense
                  hide-details
                  prepend-icon=""
                  prepend-inner-icon="mdi-attachment mdi-rotate-45"
                  v-model="form.fatura"
                  :disabled="editando"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                  label="Data referente a parcela*"
                  v-model="form.referencia"
                  type="month"
                  outlined
                  dense
                  hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  label="Data do Vencimento da Parcela*"
                  v-model="form.data_vencimento"
                  type="date"
                  outlined
                  dense
                  hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                  outlined
                  dense
                  rows="2"
                  value=""
                  hide-details
                  label="Observação"
                  v-model="form.observacao"
                  placeholder="Ex: Valor total do aluguel, Valor do condomínio do mês... "
              />
            </v-col>
          </v-row>
          <v-row v-show="!editando">
            <v-col cols="12">
              <span class="font-weight-bold font-size-16">Documentos da locação</span>
            </v-col>
            <v-col cols="12" sm="6">
              <file-uploader
                  visualizar
                  :url-visualizar="clienteAtual.contrato_imobiliaria"
                  titulo="Contrato"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <file-uploader
                  visualizar
                  :url-visualizar="clienteAtual.vistoria_imobiliaria"
                  titulo="Vistoria"
              />
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-5" v-show="!loading_btn">
            <v-col cols="12" sm="6" align="center">
              <v-btn dark color="orange" small @click="gerarInadimplencia" v-if="!editando">
                Gerar Inadimplência
              </v-btn>
              <v-btn dark color="orange" small @click="editarInadimplencia" v-else>
                Editar Inadimplência
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-5" v-show="loading_btn">
            <v-col cols="12" sm="6" align="center">
              <v-btn text small @click="editarInadimplencia">
                <v-icon>mdi-loading mdi-spin</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>

      </v-card-text>

      <dialog-detalhes ref="dialogDetalhes"/>

      <v-card-actions>
        <v-btn
            :disabled="loading_dados"
            color="orange darken-3"
            text
            @click="fechar">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinGeral from "@/mixin/geral";
import service from "@/services/inadimplencia";
import clientes from "@/services/clientes";
import AutocompleteClientes from "@/components/autocomplete/Clientes";
import DialogDetalhes from "@/components/clientes/DialogDetalhes.vue";
import FileUploader from "@/components/FileUploader.vue";
import inadimplencia from "@/services/inadimplencia";


export default {
  name: "DialogFormularioInadimplencia",
  mixins: [mixinGeral],
  components: {
    FileUploader,
    DialogDetalhes,
    AutocompleteClientes
  },
  props: {
    inadimplencia_id: Number,
    aberto: Boolean,
    cliente_id: Number,
    editando: Boolean,
  },
  data() {
    return {
      datepicker: false,
      datepicker1: false,
      loading_dados: false,
      loading_btn: false,
      clienteAtual: {},
      dados: {
        cliente_id: null,
        cliente: null,
        venda_id: null,
        valor_aluguel: null,
        valor_condominio: null,
        valor_agua: null,
        valor_luz: null,
        valor_iptu: null,
        total_garantia_utilizado: null,
        garantia: null,
      },
      checkbox_itens: {
        valor_aluguel: false,
        valor_condominio: false,
        valor_iptu: false,
        valor_agua: false,
        valor_luz: false,
        valor_reforma: false,
        valor_taxa: false,
        valor_multa: false,

      },
      form: {
        inadimplencia_id: null,
        venda_id: null,
        valor: 0,
        itens: [],
        referencia: null,
        observacao: null,
        data_vencimento: null,
        fatura: null,
      }
    };
  },
  created() {
    this.$emit('filtrar', this.form)
  },
  watch: {
    inadimplencia_id(val) {
      if (val != null || val != undefined) {
        this.buscarInadimplencia(val)
      }
    },
    cliente_id(val) {
      if (val != null || val != undefined) {
        this.obterDados(val);
      }
    },
  },
  methods: {
    abrirDetalhesClientes() {
      this.$refs.dialogDetalhes.dados = this.clientes.find(itm => itm.id === this.dados.cliente_id);
      this.$refs.dialogDetalhes.exibir();
    },
    statusRetornado(value) {
      this.form.status_id = value
    },
    clienteRetornado(value) {
      this.obterDados(value);
    },
    fechar() {
      this.resetObjetos();
      this.$emit('fechar', false);
    },
    async buscarInadimplencia(id) {

      await inadimplencia.buscar(id).then(resp => {
        this.form.inadimplencia_id = resp.data.id;
        this.form.venda_id = resp.data.venda_id;
        this.form.valor = resp.data.valor;
        this.form.itens = resp.data.itens.split(",");
        this.form.referencia = resp.data.referencia;
        this.form.observacao = resp.data.observacao;
        // this.form.fatura = resp.data.fatura;
        const [date, time] = resp.data.data_vencimento.split(" ");
        this.form.data_vencimento = date;
      })
    },
    async obterDados(id) {
      await clientes.buscar(id).then(resp => {
        const venda = resp.data.vendas.slice(-1).pop()
        this.clienteAtual = resp.data;

        this.dados.cliente_id = resp.data.id;
        this.dados.cliente = resp.data.nome;
        this.form.venda_id = venda.id;

        this.dados.venda_id = venda.id;
        this.dados.garantia = Number(venda.garantia);
        this.dados.total_garantia_utilizado = Number(venda.total_garantia_utilizado);
        this.dados.valor_aluguel = venda.valor_aluguel;
        this.dados.valor_condominio = venda.valor_condominio;
        this.dados.valor_agua = venda.valor_agua;
        this.dados.valor_luz = venda.valor_luz;
        this.dados.valor_iptu = venda.valor_iptu;
      });
    },
    async gerarInadimplencia() {
      this.loading_btn = true
      await service.gravar(this.form).then(resp => {
        this.loading_btn = false
        if (resp.status == 201) {
          this.setNotificacao({
            status: true,
            mensagem: resp.data.mensagem,
            categoria: 200
          });
          this.fechar();
        }
      }).catch(error => {
        this.loading_btn = false
        this.setNotificacao({
          status: true,
          mensagem: error.response.data.erros,
          categoria: 400
        });
      });
    },
    async editarInadimplencia() {
      this.loading_btn = true
      await service.editar(this.form.inadimplencia_id, this.form).then(resp => {
        this.loading_btn = false
        if (resp.status == 201) {
          this.setNotificacao({
            status: true,
            mensagem: resp.data.mensagem,
            categoria: 200
          });
          this.fechar();
        }
      }).catch(error => {
        this.loading_btn = false
        this.setNotificacao({
          status: true,
          mensagem: error.response.data.erros,
          categoria: 400
        });
      });
    },
    resetObjetos() {
      this.form.itens = [];
      this.form.valor = 0;
      this.dados.cliente_id = null;
    }
  }
};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.card-simple {
  border: 1px solid #99999999;
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>
