import {backend, multipart} from "./backend";
import helpers from "../assets/js/helpers";

const listar = async () => {
    let uri = "api/inadimplencia";
    let response = await backend.get(uri, helpers.montarHeaderToken());
    return response;
};
const buscar = async (id) => {
    let uri = "api/inadimplencia/" + id;
    let response = await backend.get(uri, helpers.montarHeaderToken());
    return response;
};

const gravar = async (formulario) => {
    const formData = new FormData();
    formData.append('venda_id', formulario.venda_id)
    formData.append('valor', formulario.valor)
    formData.append('itens', formulario.itens)
    formData.append('referencia', formulario.referencia)
    formData.append('observacao', formulario.observacao)
    formData.append('data_vencimento', formulario.data_vencimento)
    formData.append('fatura', formulario.fatura)

    let uri = "api/inadimplencia";
    return await multipart.post(uri, formData, helpers.montarHeaderToken());
};
const editar = async (id, formulario) => {
    const formData = new FormData();
    formData.append('inadimplencia_id', formulario.inadimplencia_id)
    formData.append('valor', formulario.valor)
    formData.append('itens', formulario.itens)
    formData.append('referencia', formulario.referencia)
    formData.append('observacao', formulario.observacao)
    formData.append('data_vencimento', formulario.data_vencimento)
    formData.append('fatura', formulario.fatura)

    let uri = "api/inadimplencia/editar/"+id;
    return await multipart.post(uri, formData, helpers.montarHeaderToken());
};

const auditarInadimplencia = async (id, formulario) => {
    const formData = new FormData();
    formData.append('comprovante', formulario.comprovante)
    formData.append('previsao_pagamento', formulario.previsao_pagamento)
    formData.append('resposta_auditor', formulario.resposta_auditor)
    formData.append('status_id', formulario.status_id)

    let uri = "api/inadimplencia/auditar/" + id;
    let response = await multipart.post(uri, formData, helpers.montarHeaderToken());
    return response;
};

const atualizar = async (id, status_id) => {
    let uri = "api/inadimplencia/" + id + "/status/" + status_id;
    let response = await backend.put(uri, {}, helpers.montarHeaderToken());
    return response;
};

const verificarInadimplenciasPendentes = async (id) => {
    let uri = "api/clientes/" + id + "/inadimplencias";
    let response = await backend.get(uri, helpers.montarHeaderToken());
    return response;
};

const deletar = async (id) => {
    let uri = "api/inadimplencia/"+id;
    let response = await backend.delete(uri, helpers.montarHeaderToken());
    return response;
};


export default {
    listar,
    deletar,
    buscar,
    editar,
    gravar,
    verificarInadimplenciasPendentes,
    auditarInadimplencia,
    atualizar
};
